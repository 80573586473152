import React from 'react';

const JSONLD = () => (
  <div className="max-w-4xl mx-auto p-6 bg-white ">
    <h1 className="text-3xl font-bold mb-6 text-gray-800">Understanding Zeitgeists.org Event Data</h1>
    <p className="text-lg text-gray-700 mb-4">
      When you create an event on Zeitgeists.org, the data is structured using JSON-LD, a format that aligns with
      <a href="https://schema.org" className="text-blue-500 hover:underline ml-1">schema.org</a> standards. This ensures your event information is both machine-readable and easily understood by search engines and other platforms. Below, we'll walk through the key fields used in the JSON-LD object for events, with a focus on the specific event types we support.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">@context and @type</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>@context</strong> field is always set to "https://schema.org", indicating that we follow schema.org's definitions and structure. The <strong>@type</strong> field specifies the type of event you're creating. Zeitgeists.org supports a variety of event types, including:
    </p>
    <ul className="list-disc pl-6 text-lg text-gray-700 mb-6">
        <li><strong>ScreeningEvent</strong>: Film screenings, movie nights, or premieres.</li>
      <li><strong>LiteraryEvent</strong>: Book readings, author signings, or literary discussions.</li>
      <li><strong>EducationEvent</strong>: Workshops, lectures, or seminars focused on learning.</li>
      <li><strong>ExhibitionEvent</strong>: Art exhibitions, gallery shows, or museum displays.</li>
      <li><strong>VisualArtsEvent</strong>: Gallery openings, art shows, or any event focused on visual arts.</li>
      <li><strong>SocialEvent</strong>: Parties, networking events, or social gatherings.</li>
      <li><strong>MusicEvent</strong>: Concerts, music festivals, and other live performances.</li>
      <li><strong>DanceEvent</strong>: Dance performances, from classical ballet to modern dance.</li>
      <li><strong>PublicationEvent</strong>: Events celebrating the release of books, magazines, or other publications.</li>
      <li><strong>SaleEvent</strong>: Markets, fairs, or special sales events.</li>
      <li><strong>TheaterEvent</strong>: Live stage performances, including plays, musicals, and operas.</li>
      <li><strong>ChildrensEvent</strong>: Events designed for children, such as workshops, performances, or educational activities.</li>
      <li><strong>ComedyEvent</strong>: Stand-up shows, improv nights, or any event focused on humor.</li>
    </ul>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Basic Event Information</h2>
    <p className="text-lg text-gray-700 mb-4">
      Each event must include basic details like <strong>name</strong> (the event title) and <strong>Description</strong> (a summary of what the event is about). The <strong>disambiguatingDescription</strong> field is used for additional notes or clarifications that help distinguish this event from others. This could be something like "Director Q&A after the screening" for a ScreeningEvent.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Timing</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>startDate</strong> and <strong>endDate</strong> fields define when the event begins and ends, formatted in ISO 8601 date-time. This ensures that the event times are consistent and easily processed by various systems.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Location</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>location</strong> field specifies where the event will take place. If your event is held in a specific room within a larger venue, you can use the <strong>containedInPlace</strong> field to define the room and its parent location. Each location includes detailed information like address, geo-coordinates, and an image (sourced from the venue’s alt field in your input form).
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Organizer Information</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>organizer</strong> field lists the organizations or individuals responsible for the event. Multiple organizers can be included, and each can have a name, URL, and image description (sourced from the organizer’s alt field).
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Event Series</h2>
    <p className="text-lg text-gray-700 mb-4">
      If your event is part of a larger series, the <strong>isPartOf</strong> field links it to that series. This is useful for recurring events or campaigns that have multiple sessions or installments.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Event Status and Attendance Mode</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>eventStatus</strong> field indicates whether the event is scheduled, canceled, postponed, etc. The <strong>eventAttendanceMode</strong> field specifies if the event is online, offline, or a mix of both.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Accessibility and Cost</h2>
    <p className="text-lg text-gray-700 mb-4">
      You can use the <strong>inLanguage</strong> field to specify the language of the event, and the <strong>isAccessibleForFree</strong> field to indicate if it’s free to attend. If there’s a cost, it’s detailed in the <strong>cost</strong> field.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Sponsorship and Media</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>sponsor</strong> field lists any sponsors, while the <strong>image</strong> field includes a URL to an event image and a description (from your listing alt field).
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Keywords and Door Time</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>keywords</strong> field helps with SEO and relevance, while the <strong>doorTime</strong> field specifies when attendees can start arriving, also formatted in ISO 8601 date-time.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Offers and External Links</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>offers</strong> field provides information about ticketing, including price and purchasing URLs. The <strong>sameAs</strong> field is an array of URLs linking to additional pages about the event, like social media or ticketing sites.
    </p>

    <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">Unique Identifier and Zeitgeists-Specific Information</h2>
    <p className="text-lg text-gray-700 mb-4">
      The <strong>identifier</strong> field contains a unique ID for the event, such as a Zeitgeist identifier. The <strong>zeitgeists</strong> field is a custom object that holds details specific to Zeitgeists.org, such as certainty scores, the last edit time, and related URLs for places, organizers, and series.
    </p>
  </div>
);

export default JSONLD;
