/*
Confirm messasage on multi listing add
Feedback on inputs acessibility

*/



import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ScreeningForm from './ScreeningForm';
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { Link } from 'react-router-dom';
import ProgressBar from '../utilities/ProgressBar';
import Datehandling from './DateHandling';
import SetSeries from './SetSeries';
import AddSeries from '../series/AddSeries';
import PreviewListing from './PreviewListing';
import SetPlaceOrgs from './SetPlaceOrgs';
import JSONParse from './JSONParse';
import PickPlaceByOrganizer from './PickPlaceByOrganizer';
import PickRegion from './PickRegion';
import SetPO from './SetPOnone';
import SetLocation from './SetLocation';

function AddListingIn({ place, organizer,regionIn,regions }) {
  const validTypes = [
    'ScreeningEvent', 
    'LiteraryEvent', 
    'EducationEvent', 
    'ExhibitionEvent', 
    'VisualArtsEvent', 
    'SocialEvent', 
    'MusicEvent', 
    'DanceEvent', 
    'PublicationEvent', 
    'SaleEvent', 
    'TheaterEvent', 
    'ChildrensEvent', 
    'ComedyEvent',
    'Event'
  ];  
  const [ready,setReady] = useState(false);
  const [success,setSuccess] = useState(false);
  function SuccessMessage({data}) {

    return (
      <div className="fixed inset-0 bg-white flex flex-col justify-center items-center text-center p-6">
      <div className="bg-green-100 border border-green-400 text-green-700 rounded-lg px-6 py-8 shadow-lg max-w-xl w-full">
        <h2 className="text-2xl font-bold mb-4">Success!</h2>
        <p className="text-xl mb-4">Successfully created {data.listings.length} listing(s).</p>
        
        <ul className="list-disc list-inside mb-6">
          {data.listings.map((listing, index) => (
            <li key={listing.id} className="text-lg">
              Listing {index + 1}: 
              <Link className="text-blue-600 underline ml-2" to={`/listing/${listing.uuid}`}>
                Open here
              </Link>
            </li>
          ))}
        </ul>

        <button
          className="w-full bg-purple-500 hover:bg-purple-700 text-white font-bold py-4 px-8 rounded-lg shadow-md transition duration-300"
          onClick={() => { setProgress(0); setSuccess(false); setUpdateMessage(''); }}
        >
          Add More Listings
        </button>
      </div>
    </div>

    );
  }
  function FailMessage() {
    return (
      <div className="border text-xl border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
      Error adding listing.
      </div>
    );
  }
  const {
    watch,
    control,
    register,
    handleSubmit, setValue, getValues, reset, formState: {errors} } = useForm({
        defaultValues: {
          status: 'live',
          place: place || null,
          organizer: organizer || null,
          sameAs: [''],
          series: [],
          parsedDates: [],
          placeId: place ? place.id : null,
          regionId: regionIn ? regionIn.id : null,
          region: regionIn ||  null,
        },
        mode: 'onChange', // Trigger validation on each change
      });

      const parsedDates = watch('parsedDates');
      
  const type = watch('type');
  const placeId = watch('placeId');
  const regionId = watch('regionId');
  const region = watch('region');
  const [progress, setProgress] = useState(0);
  const organizers = watch('organizers');
  const locationId = watch('locationId');
  const onSubmit = (data) => submitAndConnect(data);
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message
const [additionalData, setAdditonalData] = useState({});
const submitAndConnect = async (data) => {
    // Submit form data to your API or server
    if(!data.organizers) {
      data.organizers = [];
    }
    if(organizer) {
      data.organizers = [organizer.id]
    }
    data.sameAs = data.sameAs.length > 1 || data.sameAs[0] !== '' ? data.sameAs.map(item => item.value) : []; // Extract the values from the objects
    // Call the reset function to clear the form
   await  submitListing(data)
}
const { fields: sameAsFields, append: appendSameAs, remove: removeSameAs } = useFieldArray({
    control,
    name: 'sameAs',
  });  
  const { fields: seriesFields, append: appendSeries, remove: removeSeries } = useFieldArray({
    control,
    name: 'series',
  });

useEffect(() => {
  if(parsedDates.length > 0 && name && url && type) {
    setReady(true);
} else {
  setReady(false);
}
    
}, [place,getValues])

const handleNewSeries = () => {
  console.log('new series');
}

  const nameLocation = watch('nameLocation');


const name = watch('name');
const url = watch('url');
useEffect(() => {
  if (parsedDates && parsedDates.length > 0) {
    if (name && url && type && (placeId||nameLocation)) {
      setReady(true);
    } else {
      setReady(false);
    }
  }
}, [name, url, type, parsedDates,placeId,nameLocation]);
  const submitListing = async (data) => {

  // Validate the form fields
  if (!window.confirm('Confirm add Listing')) {
    return; // Stop the submission if validation fails
  } 
  //parse dates
  if (data.startDate) {
    data.startDate = data.startDate.toISOString();
  }
  if (data.endDate) {
    data.endDate = data.endDate.toISOString();
  }
  if (data.doorTime) {
    data.doorTime = data.doorTime.toISOString();
  }
  data.parsedDates = data.parsedDates.map(date => ({
    startDate: date.startDate.toISOString(),
    endDate: date.endDate.toISOString()
  }));
if(data.place) {
  data.placeId = data.place.id;
}
if(additionalData && additionalData.worksPresented && additionalData.worksPresented.length > 0) {
data.worksPresented = additionalData.worksPresented;
}

delete data.place;
delete data.allSeries;
//when saving suggestions watch for add data
    // Create a new listing object based on the form field values
    try {
      //faetch and render many regions onload
      const response = await fetch(`/api/region/${data.regionId}/newlisting/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        body: JSON.stringify(data),
      });
      if (response.ok) {
        // Success: Listing updated successfully
        // You can display a success message here if needed
        const data = await response.json()

        setAdditonalData({})
        
        reset();
        setValue('status','live') 

        // Set a success message
        setSuccess(true);
        setUpdateMessage(<SuccessMessage data={data} />);

      
        // You can close the form here by calling a callback function if needed
    } else {
        // Error: An error occurred while updating the listing
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
    }

    } catch (error) {
      // Handle fetch error
      setUpdateMessage(<FailMessage />);
      console.error(error);
    }
  };


useEffect(() => {

}, [parsedDates,getValues])

  return (
    <div className="">
      <p>
        <span className="text-2xl font-bold mb-6"> Add New Listing</span></p>

      <form onSubmit={handleSubmit(onSubmit)} >
      {!success && <div>

 <div>

{!success && <div className="flex justify-between mt-4 mb-4">
  {progress > 0 ? (
    <button
    type="button" 
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
      onClick={() => setProgress(progress - 1)}>
      Back
    </button>
  ) : (
<button 
disabled={true}
    type="button" 
      className=" text-white py-2 px-4 rounded bg-gray-800 opacity-30"
      >
      Back
    </button>
      )}
  {progress < 6 && regionId && (
    <button
    type="button" 
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
      onClick={() => setProgress(progress + 1)}>
      Continue
    </button>
  )}
</div>}
<ProgressBar progress={progress} />
{progress === -1 ?  <JSONParse setValue={setValue} setAdditonalData={setAdditonalData}/>  : null}

  

{ progress === 0 ?  <div className="mb-6">
    <div className='text-lg font-semibold'>Event Listing Details</div>
    {!name && <p className='text-red-500'>Event Name is required.</p>}
    {!url && <p className='text-red-500'>Main External URL is required.</p>}
      {!regionId && <p className='text-red-500'>Region is requried.</p>}

    {regions && <PickRegion control={control} setValue={setValue} regions={regions} />}

<div className="mb-6">
    <label htmlFor="large-input" className="block mb-2 text-md font-medium text-gray-900 ">Event Name:</label>
    <input {...register("name", { required: "name is required" })} type="text" id="large-input" className="block w-full p-4 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-lg focus:ring-blue-500 focus:border-blue-500 " />
    {errors.name && (
    <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
  )}
</div>

<div className="mb-6">
  <label className="block mb-2 text-sm font-medium text-gray-900">External URL:</label>
  <input
    {...register('url', {
      required: "URL is required",
      pattern: {
        value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        message: "Please enter a valid URL",
      },
    })}
    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
  />
  {errors.url && (
    <p className="text-red-500 text-xs mt-1">{errors.url.message}</p>
  )}
</div>
<div className="mb-6">
    <label className="block mb-2 text-sm font-medium text-gray-900">Description (supports Markdown):</label>
    <textarea 
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
        {...register('description')}  
        rows="6"
    />
</div>
<div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900">  Event Subtitle (alternativeName): </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('alternateName')}  rows="2"  />   
     </div>


     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900">Event Special Note (Include details like Q&A, artist appearances, giveaways, etc.):</label>        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('note')}  rows="2"  />   
     </div>
   


    
            

     <div className="mb-6">
  <label className="block mb-2 text-sm font-medium text-gray-900">
    External Image URL:
  </label>
  <input
    {...register('image', {
      pattern: {
        value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        message: "Please enter a valid URL",
      },
    })}
    className="input-style"
  />
  {errors.image && (
    <p className="text-red-500 text-xs mt-1">{errors.image.message}</p>
  )}
</div>


    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        External Image Alt Text:
      </label>
      <input {...register('alt')} className="input-style" />
    </div>

    {/* Other input fields */}
</div> : (progress > 0 && <span className='m-2'>       <span className={`${errors.name || errors.url || !name|| !url ? 'text-red-500' : ''}`}>
        Details.
      </span>   <button type="button"  className="underline" onClick={()=>{ setProgress(0);}}>Go back</button>.</span>)
}


{region && progress === 1 ?  <div className="mb-6">
    <div className='text-lg font-semibold'>Set Date(s)</div>
    <label className="block mb-2 text-md font-medium text-gray-900">
    Input dates and times in event region time-zone ({region.timezone})
              </label>

            {parsedDates.length === 0 && <p className='text-red-500'>Please add at least one date.</p>}
    <div className="mb-6"></div>
    {parsedDates&&<Datehandling setValue={setValue} parsedDates={parsedDates} region={region} />}
</div> : (progress > 1 && <span className='m-2'> <span className={`${parsedDates.length === 0 ? 'text-red-500' : ''}`}>Date. </span> <button type="button"  className="underline" onClick={()=>{ setProgress(1);}}>Go back</button>.</span>)
}

{region && progress === 2 ?  <div className="mb-6">
  {regions && <p className='text-lg font-semibold'>Add Place and Organizer</p>}

  {!regions && organizer && <p className='text-lg font-semibold'>Add Place or Location</p>}
   {!regions && place && <div className='text-lg font-semibold'>Add Organizers</div>}
        
   {(!placeId&&!nameLocation) && <p className='text-red-500'>Please select a place.</p>  }

      {regions && <SetPO control={control} setValue={setValue} watch={watch} />}

      {organizer && <PickPlaceByOrganizer control={control} setValue={setValue} watch={watch} regionId={regionId}  />  }
          {place &&<SetPlaceOrgs
          control={control} // Pass control to SetPlaceOrgs
          setValue={setValue}
          watch={watch}
        /> } 
        <hr className='mt-4'></hr>
        {organizer && !placeId && (
            <SetLocation organizer={organizer} region={region} setValue={setValue} register={register} control={control} nameLocation={nameLocation} locationId={locationId} />
          )}
          
          {place&& <p className='text-lg mt-4 '  >Add  exisiting organizers to place and create new ones <Link target="_blank" className='underline' to={`/region/${region.urlName}/place/${place.urlName}/edit/organizers`}>here</Link>.</p> }

</div> : (progress > 2 && <span className='m-2'><span className={`${(!placeId&&!nameLocation) ? 'text-red-500' : ''}`}>{(organizer||regions)&&<span>Place/</span>}Organizers.</span>  <button type="button"  className="underline" onClick={()=>{ setProgress(2);}}>Go back</button>.</span>)}


{progress === 3 ?  <div className="mb-6">
    <div className='text-lg font-semibold'>Set Type Details</div>
    
    <div className="mb-6">
      {!type && <p className='text-red-500'>Please select a listing type.</p>  }
<label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Select Listing Type:</label>
<select {...register("type", { required: true })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
            Select a listing type
        </option>
        {validTypes.map(type=>(
       <option key={type} value={type}>
       {type}
   </option>
        ))}     
    </select>
</div>
{type !== 'ScreeningEvent' && (
  <div className="mb-6">
    <p className="text-lg text-gray-700 mb-4">
      The <strong>Type</strong> field specifies the type of event you're creating. Zeitgeists.org supports a variety of event types, including:
    </p>
    <ul className="list-disc pl-6 text-lg text-gray-700 mb-6">
      <li className={type === 'ScreeningEvent' ? 'bg-yellow-200' : ''}>
        <strong>ScreeningEvent</strong>: Film screenings, movie nights, or premieres.
      </li>
      <li className={type === 'LiteraryEvent' ? 'bg-yellow-200' : ''}>
        <strong>LiteraryEvent</strong>: Book readings, author signings, or literary discussions.
      </li>
      <li className={type === 'EducationEvent' ? 'bg-yellow-200' : ''}>
        <strong>EducationEvent</strong>: Workshops, lectures, or seminars focused on learning.
      </li>
      <li className={type === 'ExhibitionEvent' ? 'bg-yellow-200' : ''}>
        <strong>ExhibitionEvent</strong>: Art exhibitions, gallery shows, or museum displays.
      </li>
      <li className={type === 'VisualArtsEvent' ? 'bg-yellow-200' : ''}>
        <strong>VisualArtsEvent</strong>: Gallery openings, art shows, or any event focused on visual arts.
      </li>
      <li className={type === 'SocialEvent' ? 'bg-yellow-200' : ''}>
        <strong>SocialEvent</strong>: Parties, networking events, or social gatherings.
      </li>
      <li className={type === 'MusicEvent' ? 'bg-yellow-200' : ''}>
        <strong>MusicEvent</strong>: Concerts, music festivals, and other live performances.
      </li>
      <li className={type === 'DanceEvent' ? 'bg-yellow-200' : ''}>
        <strong>DanceEvent</strong>: Dance performances, from classical ballet to modern dance.
      </li>
      <li className={type === 'PublicationEvent' ? 'bg-yellow-200' : ''}>
        <strong>PublicationEvent</strong>: Events celebrating the release of books, magazines, or other publications.
      </li>
      <li className={type === 'SaleEvent' ? 'bg-yellow-200' : ''}>
        <strong>SaleEvent</strong>: Markets, fairs, or special sales events.
      </li>
      <li className={type === 'TheaterEvent' ? 'bg-yellow-200' : ''}>
        <strong>TheaterEvent</strong>: Live stage performances, including plays, musicals, and operas.
      </li>
      <li className={type === 'ChildrensEvent' ? 'bg-yellow-200' : ''}>
        <strong>ChildrensEvent</strong>: Events designed for children, such as workshops, performances, or educational activities.
      </li>
      <li className={type === 'ComedyEvent' ? 'bg-yellow-200' : ''}>
        <strong>ComedyEvent</strong>: Stand-up shows, improv nights, or any event focused on humor.
      </li>
    </ul>
  </div>
)}

{type==='ScreeningEvent' && <ScreeningForm setMovieData={setAdditonalData} movieData={additionalData}/>}

</div> : (progress > 3 && <span> <span className={`${!type ? 'text-red-500' : ''}`}>Type Details.</span>  <button type="button"  className="underline" onClick={()=>{ setProgress(3);}}>Go back</button>.</span>)
}


{(place || organizer) && progress === 4 ?  <div className="mb-6">    <div className='text-lg font-semibold'>Set Series Details</div>
  <SetSeries
    placeId={placeId}
    organizers={organizers || null}
    organizerID={(organizer&&organizer.id)||null}
    control={control}
    setValue={setValue}
    getValues={getValues}
    seriesFields={seriesFields}
    removeSeries={removeSeries}
    appendSeries={appendSeries}
    watch={watch}
    tempPlace={nameLocation}
/>

 <AddSeries placeId={(place&&place.id)||null} organizerId={(organizer&&organizer.id)||null} region={region} onChange={handleNewSeries} />

</div> : (progress > 4 && <span className='m-2'>Series Details.  <button type="button"  className="underline" onClick={()=>{ setProgress(4);}}>Go back</button>.</span>)   }
{progress === 5 ?  <div className="mb-6">  
    <div className='text-lg font-semibold'>Set Additional Fields</div>


    


    <div className="mb-6">
      <label className="block mb-2  mt-2 text-md font-medium text-gray-900">Event Online Instances (sameAs):</label>
      <p className='mb-4'>This field allows you to link to various online instances where your event is mentioned or promoted. These can include social media posts, official event pages, articles, or any other web pages that provide additional information about your event. By adding these links, you help to establish a stronger online presence and identity for your event.</p>
      <ul>
        {sameAsFields.map((item, index) => (
          <li key={item.id} className="mb-2">
            <input
              {...register(`sameAs.${index}.value`, {
                required: "URL is required",
                pattern: {
                  value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                  message: "Please enter a valid URL",
                },
              })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              defaultValue={item.value} // Set the default value
            />
            {errors.sameAs?.[index]?.value && (
              <p className="text-red-500 text-xs mt-1">{errors.sameAs[index].value.message}</p>
            )}
            <button type="button" onClick={() => removeSameAs(index)} className="ml-2  bg-red-500 rounded-full py-1 px-2 m-2 text-white">
              Remove
            </button>
          </li>
        ))}
      </ul>
      <button type="button" onClick={() => appendSameAs({ value: '' })} className="mt-2 text-lg bg-blue-500 text-white py-2 px-3 rounded-full">
        Add URL
      </button>
    </div>


    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Building Room:
      </label>
      <input {...register('room')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Language:
      </label>
      <input {...register('inLanguage')} className="input-style" />
    </div>


    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900">
      Is Accessible for Free:
      </label>
      <Controller
        name="isAccessibleForFree"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <label htmlFor="isAccessibleForFree-yes" className="cursor-pointer mr-4">
              <input
                id="isAccessibleForFree-yes"
                type="radio"
                value="yes"
                onChange={onChange}
                checked={value === 'yes'}
                className="mr-2"
              />
              Yes
            </label>
    
            <label htmlFor="isAccessibleForFree-no" className="cursor-pointer">
              <input
                id="isAccessibleForFree-no"
                type="radio"
                value="no"
                onChange={onChange}
                checked={value === 'no'}
                className="mr-2"
              />
              No
            </label>
          </>
        )}
      />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Sponsor:
      </label>
      <input {...register('sponsor')} className="input-style" />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Ticket Offer URL:
      </label>
      <input {...register('offerUrl',{
      pattern: {
        value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        message: "Please enter a valid URL",
      }})} className="input-style" />
      {errors.offerUrl && (
        <p className="text-red-500 text-xs mt-1">{errors.offerUrl.message}</p>
      )}
    </div>
    
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Ticket Offer Price in USD:
      </label>
      <input {...register('price')} className="input-style" />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Audience:
      </label>
      <input {...register('audience')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Keywords:
      </label>
      <input {...register('keywords')} className="input-style" />
    </div>

        
    {parsedDates.length < 2 && <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Doortime: 
      </label>
      <Controller
        name="doorTime"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            selected={field.value}
            onChange={(date) => field.onChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="input-style"
          />
        )}
      />
    </div>}



<div className="mb-6">
  <label className="block mb-2 text-sm font-medium text-gray-900">Listing Display Status:</label>
  <Controller
    name="status"
    control={control}
    render={({ field: { onChange, value } }) => (
      <>
        <input
          type="radio"
          id="status-live"
          value="live"
          onChange={onChange}
          checked={value === 'live'}
          className="mr-2"
        />
        <label htmlFor="status-live" className="mr-4 cursor-pointer">Live (visible to all)</label>

        <input
          type="radio"
          id="status-editing"
          value="editing"
          onChange={onChange}
          checked={value === 'editing'}
          className="mr-2"
        />
        <label htmlFor="status-editing" className="mr-4 cursor-pointer">Editing (changes being made)</label>

        <input
          type="radio"
          id="status-hiding"
          value="hiding"
          onChange={onChange}
          checked={value === 'hiding'}
          className="mr-2"
        />
        <label htmlFor="status-hiding" className="cursor-pointer">Hiding (hidden from view)</label>
      </>
    )}
  />
</div>
  

</div> : (progress > 5 && <span className='m-2'>Additional Fields.  <button type="button"  className="underline" onClick={()=>{ setProgress(5);}}>Go back</button>.</span>)}
{!success && (place || organizer) && progress === 6 &&  <div className="mb-6">
  <div className='text-lg font-semibold'>
    Review and Submit</div>
{!success&& <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Listing Add Comments:
      </label>
      <textarea {...register('comment')} className="input-style" />
    </div>}
{!success && <PreviewListing getValues={getValues} place={place} region={region} organizers={organizers} seriesFields={seriesFields} additionalData={additionalData} type={type} organizer={organizer} />}

<div className="mb-6 text-red-500">
{!ready && <p className=''>Please fill out all required fields. 
</p>}

{!name && <p onClick={()=>setProgress(0)} className='underline'>Add Title</p>}
{!url && <p onClick={()=>setProgress(0)} className='underline'>Add Main URL</p>}
{!parsedDates.length && <p onClick={()=>setProgress(1)} className='underline'>Add Dates</p>}
{!type && <p onClick={()=>setProgress(3)} className='underline'>Select Type</p>}
{(!placeId && !nameLocation) && <p onClick={()=>setProgress(2)} className='underline'>Select Place</p>}

  </div>


<div className='w-full text-center'>
<button
  type="submit"
  className={`bg-green-300 hover:bg-black text-black font-semibold hover:text-white py-4 px-8 border border-black hover:border-transparent rounded text-lg ${!ready ? 'opacity-50 cursor-not-allowed' : ''}`}
  disabled={!ready}
>
  Confirm Add Listing
</button>

  </div>
</div>  }
<hr></hr>
<div className="flex justify-between mt-4 mb-4">
  {!success && progress > 0 ? (
    <button
    type="button" 
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
      onClick={() => setProgress(progress - 1)}>
      Back
    </button>
  ) : (
<button disabled={true}
    type="button" 
      className=" text-white py-2 px-4 rounded bg-gray-800 opacity-30"
      >
      Back
    </button>
      )}
  {progress < 6 && regionId && (
    <button
    type="button" 
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
      onClick={() => setProgress(progress + 1)}>
      Continue
    </button>
  )}
</div>
{progress < 2 && progress > -1 && (
  <div className="justify-center mt-4 mb-4">
    <div className="w-full">
      <p className="text-lg mb-2">
        We are experimenting with a ChatGPT bot that automatically gathers most of the data from the event page and structures it into JSON, saving you from manual entry.
      </p>
      <p className="text-lg mb-2">
        You can then paste the bot’s output into our JSON formatter. Note: This bot is available only to ChatGPT Premium users.
      </p>
      <a 
        href="https://chatgpt.com/g/g-egwkgns4k-zeitgeists-org-event-json-formatter" 
        className="text-blue-500 underline mb-4 inline-block"
        target='_blank'
      >
        Try the JSON Formatter here
      </a>
    </div>
    <button 
      type="button" 
      className="bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-700 mt-2"
      onClick={() => setProgress(-1)}
    >
      JSON Input
    </button>
  </div>
)}


          </div> 
        

     
          </div>}

{updateMessage && <p>{updateMessage}</p>}
       
      </form>

    </div>
  );
}

export default AddListingIn;
