import React, { useEffect, useRef } from 'react';
import {  Link } from 'react-router-dom';
import ProfileImage from '../user/ProfileImage';
import DisplayUsers from '../utilities/DisplayUsers';
import MapComponent from '../utilities/MapComponent';
import PlacesCTA from './PlacesCTA';
function PlaceInfo({ place, organizers, light,deletePlace,handleDelete, listing,noMap,home,activePlace,border, setActivePlace,region}) {
  // Create a ref
  const placeRef = useRef(null);
  // Use useEffect to scroll the div into view
  useEffect(() => {
    if (activePlace  && placeRef.current) {
      placeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activePlace, place])
  const placeUrl = (edit) => {
    let url;
    if(place.region) {
      url = `/region/${place.region.urlName}/place/${place.urlName}`
    } else if (region) {
      url = `/region/${region.urlName}/place/${place.urlName}`
    } else {
      url = `/region/${place.regionId}/place/${place.urlName}`
    }
    if(edit) {
      url = url +'/edit'
    }
    return (
      url
    )
  }
  const organizerUrl = (organizer) => {
    let url;
    if(organizer.region) {
      url = `/region/${organizer.region.urlName}/organizer/${organizer.urlName}`
    } else if (region) {
      url = `/region/${region.urlName}/organizer/${organizer.urlName}`
    } else if (place.region) {
      url = `/region/${place.region.urlName}/organizer/${organizer.urlName}`
    } else  {
      url = `/region/${place.regionId}/organizer/${organizer.urlName}`
    }

    return (
      url
    )
  }

  return (
      <div className={light||border ? 'm-1 text-center border-2 rounded-lg p-2 border-black' : 'm-3 text-center'} ref={placeRef}>
      {place && (
        <div key={place.id} >
          {!home&&
          <div className="text-right ">{deletePlace&&[<span><button onClick={()=>handleDelete(place.id)} className="inline-block m-2 bg-red-500 hover:bg-red-900 hover:text-white  border rounded-full border-black p-1 ">
              Delete place
            </button></span>]}
            <Link to={placeUrl(true)} className="inline-block mt-2 bg-yellow-500 hover:bg-yellow-900 hover:text-white  border rounded-full border-black p-1 ">
              Edit place
            </Link>
            </div>}
                <div className='m-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {place.image  && <ProfileImage url={place.image} alt={place.alt} />}
                </div>   
                <h2 className="text-2xl font-semibold mt-4"><Link to={placeUrl()} className="hover:underline">{place.name}</Link></h2>
          {!listing && organizers && place.organizers && organizers.length > 0 && (
        <div className="m-2">
          {place.organizers.map((orgId,index) => {
            const organizer = organizers.find(org=>org.id===orgId);
            if(organizer) {
              return <span className='inline-block mt-2' key={index}>
              <Link to={organizerUrl(organizer)} className="mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black ">
              {organizer.name}
            </Link>
            </span>
            }
          }
          )}
        </div>
      )}
          
    {place.url&& <a
      href={place.url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:underline break-all"
    >
      {place.url}
    </a>}



                    {!listing && (place.region|| region) && !light &&
            <p className="mt-2">
              Region:{' '}
              <Link className='text-blue-500 hover:underline' to={`/region/${place.region ? place.region.urlName : region.urlName}`}>
                {place.region ? place.region.name : region.name}
              </Link>
            </p>
          }
                    {place.area && <p className="mt-2">Neighborhood: {place.area}</p>}

                   
          {!listing && place.owns && !light && place.owns.length > 0 && (
            <div className="mt-2 ">
              Owners:
              <DisplayUsers ids={place.owns}/>
            </div>
          )}
          {!listing&& place.manages &&!light &&place.manages.length > 0 && (
            <div className="mt-2 ">
              Managers:
              <DisplayUsers ids={place.manages}/>
            </div>
          )}
          {place.description && <p className="mt-2">{place.description.length>300&& border ? place.description.substring(0,300) + '...'   : place.description}</p>}
          {place.streetAddress && <p className="mt-2">Address: {place.streetAddress}, {place.addressLocality}, {place.addressRegion} {place.postalCode}, {place.addressCountry}</p>     }
          {!listing && !border && place.accessibility && !light &&(
            <p className="mt-2">Accessibility Information: {place.accessibility}</p>
          )}
          {!listing && place.sameAs && !light && place.sameAs.length > 0 && place.sameAs[0].url && (
            <div className="mt-2">
              <p>External Links:</p>
              <ul>
                {place.sameAs.map((x, index) => (
                  <li key={index}>
                    <a href={x.url} target="_blank" rel="noopener noreferrer">
                      {x.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {place && !light && !noMap && !home&& <PlacesCTA place={place} region={region} />} 
               {place.latitude && place.longitude && !light && !noMap && !isNaN(place.latitude)  && !isNaN(place.longitude)  && (
            <div className="mt-2">
              <MapComponent position={{lat:place.latitude, lng: place.longitude }} />
            </div>
          )}
        </div>
      )}
      </div>
  

  );
}

export default PlaceInfo;
