import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SetPlaceOrgsNew from '../place/SetPlaceOrgsNew';
import { useNavigate } from 'react-router-dom';
import ScreeningForm from './ScreeningForm';
import SetSeries from './SetSeries';

import { useForm, Controller,useFieldArray } from "react-hook-form"
function EditListingNew({ listing, editor, onChange }) {
  const navigate = useNavigate();

  const validTypes = [
    'ScreeningEvent', 
    'LiteraryEvent', 
    'EducationEvent', 
    'ExhibitionEvent', 
    'VisualArtsEvent', 
    'SocialEvent', 
    'MusicEvent', 
    'DanceEvent', 
    'PublicationEvent', 
    'SaleEvent', 
    'TheaterEvent', 
    'ChildrensEvent', 
    'ComedyEvent',
    'Event'
  ];  
  function SuccessMessage({data}) {
    let message = 'Success';
    if(data.listing) {
      message = data.message
    } else if (data.suggestion) {
      message = data.message;
    }
    
    return (
      <div className="border text-xl border-green-400 rounded  bg-green-100 px-4 py-3 text-green-700">
        {message}
  </div>

    );
  }
  function FailMessage({data}) {
    return (
      <div className="border text-xl border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
      Error editing listing.
      </div>
    );
  }
  const parseDate = (date) => {
    const parsedDate = Date.parse(date);
    return !isNaN(parsedDate) ? new Date(parsedDate) : null;
  };
  
  const parseOrganizers = (organizers) => organizers || [];
  
  const parseSameAs = (sameAs) => 
    sameAs?.map(s => ({
      value: s.url,
      id: s.id,
    })) || [];
  
  const parseSeries = (series) => 
    series?.map(s => ({
      uuid: s.series.uuid,
      name: s.series.name,
    })) || [];

    const {
      control,
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      formState: { errors }
    } = useForm({
      defaultValues: {
        startDate: new Date(listing.startDate),
        endDate: listing.endDate ? new Date(listing.endDate) : null,
        doorTime: listing.doorTime ? new Date(listing.doorTime) : null,
        organizers: parseOrganizers(listing.organizers),
        sameAs: parseSameAs(listing.sameAs),
        series: parseSeries(listing.series),
        allSeries: [],
      },
      mode: 'onChange', // Trigger validation on each change
    });


    useEffect(() => {
      Object.entries(listing).forEach(([key, value]) => {
        if (key === 'startDate') {
          setValue(key, new Date(value));
        } else if (key === 'endDate' || key === 'doorTime') {
          setValue(key, value ? new Date(value) : null);
        } else if (key === 'sameAs') {
          setValue(key, value.map(s => ({
            value: s.url,
            id: s.id
          })));
        } else if (key === 'series') {
          setValue(key, value.map(s => ({
            uuid: s.series.uuid,
            name: s.series.name
          })));
        } else {
          setValue(key, value);
        }
      });
    }, [listing, setValue]);
      const type = watch('type');
      const startDate = watch('startDate');
      const endDate = watch('endDate');
     




  const [dateNote, setNote] = useState(''); // State for date validation note

  const validateDate = (startDate, endDate) => {
    if (!startDate) {
      setNote('Start date is required.');
      return false;
    }
    if (endDate && endDate < startDate) {
      setNote('End date must be after start date.');
      return false;
    }
    setNote('');
    return true;
  };

  const handleStartDateChange = (date) => {
    setValue('startDate', date);
    validateDate(date, endDate);
  };
  
  const handleEndDateChange = (date) => {
    setValue('endDate', date);
    validateDate(startDate, date);
  };

  const formatEventTime = (startDate, endDate) => {
    if (!startDate) return '';
    
    const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: listing.region.timezone };
    const startTime = startDate.toLocaleTimeString('en-US', optionsTime);
    const startDateStr = startDate.toLocaleDateString('en-US', optionsDate);
    
    if (!endDate) {
      return `The event starts at ${startTime} ${listing.region.name} time on ${startDateStr}.`;
    }
    
    const endTime = endDate.toLocaleTimeString('en-US', optionsTime);
    const endDateStr = endDate.toLocaleDateString('en-US', optionsDate);
    return `The event starts at ${startTime} ${listing.region.name} time on ${startDateStr} and ends at ${endTime} on ${endDateStr}.`;
  };
      
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message
const [additionalData, setAdditonalData] = useState({});
const { fields: sameAsFields, append: appendSameAs, remove: removeSameAs } = useFieldArray({
  control,
  name: 'sameAs',
});  

const { fields: seriesFields, append: appendSeries, remove: removeSeries } = useFieldArray({
  control,
  name: 'series',
});
const removeEmptyFields = (data) => {
  Object.keys(data).forEach(key => {
    if (data[key] === null || data[key] === "") {
      delete data[key];
    }
  });
  return data;
};

const onSubmit = async (data) => {
    // Submit form data to your API or server
    if(data.place) {
      data.placeId = data.place.id;
    }
    if(additionalData && additionalData.worksPresented && additionalData.worksPresented.length > 0) {
    data.worksPresented = additionalData.worksPresented;
    }
    
    delete data.place;
    delete data.allSeries;
    if(data.isAccessibleForFree==="") {
      data.isAccessibleForFree = null;
    }
    delete data.allSeries
    data.sameAs = data.sameAs.length > 1 || data.sameAs[0] !== '' ? data.sameAs.map(item => item.value) : []; // Extract the values from the objects
    setAdditonalData({})
    
    // Call the reset function to clear the form
   await submit(data);
    
  }




  function removeHtmlTags(input) {
    return input.replace(/<[^>]*>/g, '');
  }
  const [showMore,setShowMore] = useState(false);
  const [suggestMore,setSuggestMore] = useState(false);

  const handleShowMore = () => {
    if(showMore) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  };
  const handleSuggestMore = () => {
    if(suggestMore) {
      setSuggestMore(false);
    } else {
      setSuggestMore(true);
    }
  };



  const submit = async (data) => {
    
    // Create a new listing object based on the form field values
    try {
      const response = await fetch(`/api/listing/${listing.uuid}/edit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include', // Include cookies
      });
      if (response.ok) {
        const data = await response.json()
        setValue('comment', '')

        setUpdateMessage(<SuccessMessage data={data} />)
        // Success: Listing updated successfully
        // You can display a success message here if needed
        setTimeout(() => setUpdateMessage(''), 10000); // Clear message after 5 seconds

        onChange();
        // You can close the form here by calling a callback function if needed
    } else {
      if(response.status===401) {
        navigate(`/login/listing/${listing.uuid}/edit`)
      }
      setUpdateMessage(<FailMessage data={data} />);

        // Error: An error occurred while updating the listing
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
    }

    } catch (error) {
   
        setUpdateMessage(<FailMessage />);
    
      console.error(error);
    }
  };


  return (
    <div className="m-4">
       {!editor && <p className="text-center text-2xl underline text-bold text-orange-400">
            Suggest Edit
        </p>}

      <form onSubmit={handleSubmit(onSubmit)} >
<div>
{!editor && <div className="mb-6">
  <div className="mb-6">
        <label className="block mb-2 text-lg font-medium text-gray-900 ">
            Suggestions or Comments:
            </label>

              <textarea   {...register('comment', {required: true})} rows={4} className="input-style text-lg" />
              </div>
              <div className="mb-6">
  <label htmlFor="eventStatus" className="block mb-2 text-lg font-medium text-gray-900">
    Event Status Change:
  </label>
  <p className="text-sm text-gray-600 mb-2">
    Select the current status of the event. The default is 'Scheduled,' which means the event is set to occur. Only change this if the event's status has changed (e.g., canceled or postponed).
  </p>
  <select 
    {...register("eventStatus", { required: true })} 
    defaultValue="https://schema.org/EventScheduled"
    className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
  >
    <option value="https://schema.org/EventScheduled">Scheduled</option>
    <option value="https://schema.org/EventCancelled">Cancelled</option>
    <option value="https://schema.org/EventPostponed">Postponed</option>
    <option value="https://schema.org/EventRescheduled">Rescheduled</option>
  </select>
</div>
<button type="button" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" onClick={handleSuggestMore}>Show Listing Fields</button>

    </div>}
{(editor || suggestMore) && <>
<div className="mb-6">
    <label htmlFor="large-input" className="block mb-2 text-sm font-medium text-gray-900 ">Event Name:</label>
    <input {...register("name", { required: "Name is required" })} type="text" id="large-input" className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 " />
    {errors.name && (
    <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
  )}
</div>

<div className="mb-6">
        <SetPlaceOrgsNew
          control={control} // Pass control to SetPlaceOrgs
          setValue={setValue} // Pass setValue to SetPlaceOrgs for updating values
          getValues={getValues}
          thePlace={listing.placeId}
          preOrgs={listing.organizers}
          region={listing.regionId}
          suggest={!editor}
          tempPlace={listing.locationId||null}
        />  
</div>

<div className="mb-6">
<label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Select Event Type:</label>
<select {...register("type", { required: true })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
            Select an event type
        </option>
        {validTypes.map(type=>(
       <option key={type} value={type}>
       {type}
   </option>
        ))}   

     
    </select>
</div>

<div className='mb-4'>
        <h3 className="mb-2 font-heavy text-lg">Date:</h3>
        <p className='mb-4 text-lg'>{formatEventTime(startDate, endDate)}</p>
        <p>{dateNote}</p>
        <div className='flex space-x-2'>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">
              Start Date
            </label>
            <Controller
  name="startDate"
  control={control}
  rules={{ required: "Start date is required" }}
  render={({ field }) => (
    <DatePicker
      {...field}
      selected={field.value}
      onChange={(date) => {
        field.onChange(date);
        handleStartDateChange(date);
      }}
      showTimeSelect
      dateFormat="Pp"
      className="w-full p-2 border-2 rounded mb-2 mx-1"
    />
  )}
/>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">
              End Date
            </label>
            <Controller
  name="endDate"
  control={control}
  render={({ field }) => (
    <DatePicker
      {...field}
      selected={field.value}
      onChange={(date) => {
        field.onChange(date);
        handleEndDateChange(date);
      }}
      showTimeSelect
      dateFormat="Pp"
      isClearable
      className="w-full p-2 border-2 rounded mb-2 mx-1"
    />
  )}
/>
          </div>
        </div>
      </div>





<div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900">  Event Subtitle (alternateName): </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('alternateName')}    />   
     </div>


  <div className="mb-6">
  <label className="block mb-2 text-sm font-medium text-gray-900">Main External URL:</label>
  <input
    {...register('url', {
      required: "URL is required",
      pattern: {
        value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        message: "Please enter a valid URL",
      },
    })}
    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
  />
  {errors.url && (
    <p className="text-red-500 text-xs mt-1">{errors.url.message}</p>
  )}
</div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900">Event Note (Include details like Q&A, artist appearances, giveaways, etc.):</label>        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('note')}  rows="2"  />   
     </div>
     <div className="mb-6">
    <label className="block mb-2 text-sm font-medium text-gray-900">Description (supports Markdown):</label>
    <textarea 
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
        {...register('description')}  
        rows="4"
    />
</div>
{
  editor && <SetSeries
  placeId={listing.place&&listing.place.id||null}
  locationId={listing.locationId||null}
  organizers={listing.organizers.map((org, index) => org.organizerId) || null}
  organizerID={listing.organizerSpecificPlace&& listing.organizerSpecificPlace.organizerId || null}
  control={control}
  setValue={setValue}
  getValues={getValues}
  seriesFields={seriesFields}
  removeSeries={removeSeries}
  appendSeries={appendSeries}
  watch={watch}
/>
}




<div className="mb-6">
      <label className="block mb-2  mt-2 text-md font-medium text-gray-900">Event Online Instances (sameAs):</label>
      <p className='mb-4'>This field allows you to link to various online instances where your event is mentioned or promoted. These can include social media posts, official event pages, articles, or any other web pages that provide additional information about your event. By adding these links, you help to establish a stronger online presence and identity for your event.</p>
      <ul>
        {sameAsFields.map((item, index) => (
          <li key={item.id} className="mb-2">
            <input
              {...register(`sameAs.${index}.value`, {
                required: "URL is required",
                pattern: {
                  value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                  message: "Please enter a valid URL",
                },
              })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              defaultValue={item.value} // Set the default value
            />
            {errors.sameAs?.[index]?.value && (
              <p className="text-red-500 text-xs mt-1">{errors.sameAs[index].value.message}</p>
            )}
            <button type="button" onClick={() => removeSameAs(index)} className="ml-2  bg-red-500 rounded-full py-1 px-2 m-2 text-white">
              Remove
            </button>
          </li>
        ))}
      </ul>
      <button type="button" onClick={() => appendSameAs({ value: '' })} className="mt-2 text-lg bg-blue-500 text-white py-2 px-3 rounded-full">
        Add URL
      </button>
    </div>
       
          {!showMore && <button type="button" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" onClick={()=>handleShowMore()}>Show Additional Fields</button>}
          {showMore && (
  <>
  <div className="block mb-5 mt-10 font-medium text-gray-900 ">
    Additional Fields - <button type="button" onClick={()=>handleShowMore()}>Close</button>
  </div>
  <div className="mb-6">
  <label htmlFor="eventStatus" className="block mb-2 text-sm font-medium text-gray-900">
    Event Status:
  </label>
  <p className="text-sm text-gray-600 mb-2">
    Select the current status of the event. The default is 'Scheduled,' which means the event is set to occur. Only change this if the event's status has changed (e.g., canceled or postponed).
  </p>
  <select 
    {...register("eventStatus", { required: true })} 
    defaultValue="https://schema.org/EventScheduled"
    className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
  >
    <option value="https://schema.org/EventScheduled">Scheduled</option>
    <option value="https://schema.org/EventCancelled">Cancelled</option>
    <option value="https://schema.org/EventPostponed">Postponed</option>
    <option value="https://schema.org/EventRescheduled">Rescheduled</option>
  </select>
</div>
<div className="mb-6">
  <label htmlFor="eventAttendanceMode" className="block mb-2 text-sm font-medium text-gray-900">
    Event Attendance Mode:
  </label>
  <p className="text-sm text-gray-600 mb-2">
    Specify how attendees can participate in the event. The default is 'Offline (In-Person).' Only change this if the event is being held online or both online and offline.
  </p>
  <select 
    {...register("eventAttendanceMode", { required: true })} 
    defaultValue="https://schema.org/OfflineEventAttendanceMode"
    className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
  >
    <option value="https://schema.org/OfflineEventAttendanceMode">Offline (In-Person)</option>
    <option value="https://schema.org/OnlineEventAttendanceMode">Online</option>
    <option value="https://schema.org/MixedEventAttendanceMode">Mixed (Offline & Online)</option>
  </select>
</div>


    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Building Room:
      </label>
      <input {...register('room')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Language:
      </label>
      <input {...register('inLanguage')} className="input-style" />
    </div>


   
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900">
      Is Accessible for Free:
      </label>
      <Controller
        name="isAccessibleForFree"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <label htmlFor="isAccessibleForFree-yes" className="cursor-pointer mr-4">
              <input
                id="isAccessibleForFree-yes"
                type="radio"
                value="yes"
                onChange={onChange}
                checked={value === 'yes'}
                className="mr-2"
              />
              Yes
            </label>
    
            <label htmlFor="isAccessibleForFree-no" className="cursor-pointer">
              <input
                id="isAccessibleForFree-no"
                type="radio"
                value="no"
                onChange={onChange}
                checked={value === 'no'}
                className="mr-2"
              />
              No
            </label>
          </>
        )}
      />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Sponsor:
      </label>
      <input {...register('sponsor')} className="input-style" />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Ticket Offer URL:
      </label>
      <input {...register('offerUrl',{
      pattern: {
        value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        message: "Please enter a valid URL",
      }})} className="input-style" />
      {errors.offerUrl && (
        <p className="text-red-500 text-xs mt-1">{errors.offerUrl.message}</p>
      )}
    </div>
    
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Ticket Offer Price in USD:
      </label>
      <input {...register('price')} className="input-style" />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Audience:
      </label>
      <input {...register('audience')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        External Image URL:
      </label>
      <input {...register('image')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        External Image Alternative Text:
      </label>
      <input {...register('alt')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Keywords:
      </label>
      <input {...register('keywords')} className="input-style" />
    </div>

    <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900">
              Doortime:
            </label>
            <Controller
              name="doorTime"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  isClearable
                  className="input-style"
                />
              )}
            />
          </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Status:
      </label>
      <Controller
  name="status"
  control={control}
  render={({ field: { onChange, value } }) => (
    <>
      <input
        type="radio"
        value="live"
        onChange={onChange}
        checked={value === 'live'}
        className="mr-2"
      />
      Live
      <input
        type="radio"
        value="editing"
        onChange={onChange}
        checked={value === 'editing'}
        className="ml-4 mr-2"
      />
      Editing
      <input
        type="radio"
        value="hiding"
        onChange={onChange}
        checked={value === 'hiding'}
        className="ml-4 mr-2"
      />
      Hiding
    </>
  )}
/>

    </div>

    <button type="button" className="button-style" onClick={()=>handleShowMore()}>
      Close Additional Fields
    </button>
  </>
)}

      
            <br/>

         {type==='ScreeningEvent' && <ScreeningForm setMovieData={setAdditonalData} movieData={additionalData} listing={listing} />}
{listing.workingText && <p>
Scraped Working Text: {removeHtmlTags(listing.workingText)}
</p>
}
</>  }

    {updateMessage}

    <div className="flex justify-center mt-6 mb-4">
  <button 
    type="submit" 
    className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
  >
    {!editor ? 'Suggest an Edit' : 'Save Changes'}
  </button>
</div>
<hr />
       </div>
      </form>

    </div>
  );
}

export default EditListingNew;
