/*
TODO
-sort by series UUID!
*/

import React, { useEffect, useState } from 'react';

import DisplayListing from './DisplayListing';
import { useNavigate , useLocation } from 'react-router-dom';
import ListingsIntro from './ListingsIntro';
import { MultiSelect } from "react-multi-select-component";
import { useForm, Controller } from "react-hook-form"



const typeOptions = [
  {label: 'ScreeningEvent', value: 'ScreeningEvent'},
  {label: 'LiteraryEvent', value: 'LiteraryEvent'},
  {label: 'EducationEvent', value: 'EducationEvent'},
  {label: 'ExhibitionEvent', value: 'ExhibitionEvent'},
  {label: 'VisualArtsEvent', value: 'VisualArtsEvent'},
  {label: 'SocialEvent', value: 'SocialEvent'},
  {label: 'MusicEvent', value: 'MusicEvent'},
  {label: 'DanceEvent', value: 'DanceEvent'},
  {label: 'PublicationEvent', value: 'PublicationEvent'},
  {label: 'SaleEvent', value: 'SaleEvent'},
  {label: 'TheaterEvent', value: 'TheaterEvent'},
  {label: 'ChildrensEvent', value: 'ChildrensEvent'},
  {label: 'ComedyEvent', value: 'ComedyEvent'},
  {label: 'Event', value: 'Event'},
];
function GetListings({ listings,organizers,places,sort,regions,types,count, filter, onChange,hidefilters,placePage,isAuthenticated,series}) {
  //cover for bad listings dont show
  //count less than 20
  const {
    control,
    register,
    handleSubmit, setValue, getValues,watch } = useForm({
        defaultValues: {
        }
      });
      const selectedTypes = watch('types');     
      
      const region = watch('region');
      const status = watch('status');      
      useEffect(() => {
        fetchListings({ types:selectedTypes, region, status });
      }, [selectedTypes, region, status]);
  const navigate = useNavigate()
    const [listingsData, setListingsData] = useState(false);
    const [placesData, setPlacesData] = useState(false);
    const [organizersData, setOrganizersData] = useState(false);
    const [regionsData, setRegionsData] = useState(false);
    const [suggestionsData, setSuggestionsData] = useState(false);
    const [totalCount, setTotalCount] = useState(10);
    const [sortByDate, setSortByDate] = useState(1); 
    const handleMultiSelectChange = (selectedOptions) => {
      setValue('types', selectedOptions);
    };
    
    useEffect(() => {
    }, [selectedTypes]);
    

    //filter field for refetching after the first
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageQuery = searchParams.get('page');
    const [pages, setPages] = useState({nextPage: null, previousPage: null});
    const suggestionQuery = searchParams.get('suggestions');
    const [currentPage, setCurrentPage] = useState(pageQuery ? parseInt(pageQuery, 10) : 0);
    const [pageNumber, setPageNumber] = useState(pageQuery ? parseInt(pageQuery, 10) : 0);
    const [suggestionsSort, setSuggestionsSort] = useState((suggestionQuery ==='add'|| suggestionQuery ==='edit') ? suggestionQuery : false)
    const [showSuggested, setShowSuggested] = useState(suggestionQuery === 'add' || false);
  
    const maxPerPage = count ? count : 20;
    //paginate first
  const fetchListings = async (data) => {
    const body = {
      ...(listings !== undefined && { listings }),
      ...(organizers !== undefined && { organizers }),
      ...(places !== undefined && { places }),
      ...({regions: [data && data.region]}),
      ...({status: data && data.status}),
      ...(series !== undefined && { series }),
      ...(((data && data.types && data.types.length > 0) && {types: data.types.map(type=>(type.value))}) ),
      ...(sort !== undefined ? { sort } : (!isNaN(sortByDate) && {sort: sortByDate})),
      ...(count !== undefined && { countPerPage: count }),
    };
  
  
    try {
      if(!currentPage ||isNaN(currentPage )||currentPage  < 0) {

        setCurrentPage(0)
      }
      const response = await fetch(`/api/listings/page/${currentPage}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        setCurrentPage(data.currentPage)
        setListingsData(data.listings);
        setRegionsData(data.regions);
        setTotalCount(data.count);
        setSuggestionsData(data.suggestions)
        setPages({...data.pages})

      } else if (response.status === 401) {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('role');
      }

    }
    catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    fetchListings();
  }, [currentPage, onChange]);

  const handleApplyFilter = () => {
    // When filters change, reset the page number to 0
    // and fetch listings for the first page.
    handlePageChange(0);
    // Delay fetching to avoid it being called again by the pageNumber changing
    const timer = setTimeout(() => {
      fetchListings();
    }, 0);
  
    // Cleanup function to cancel the timeout if the component unmounts
    // or if the effect runs again before the timeout fires.
    return () => clearTimeout(timer);
  }; 
  

      const handlePageChange = (newPageNumber) => {
        // Update the URL with the new page number
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('page', newPageNumber);
        setCurrentPage(newPageNumber)
        navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
        // Call fetchListings to reload data based on new page
      };

    const getOrganizers = (orgIds) => {
        const orgs = organizersData.filter(org => orgIds.includes(org._id));
        return orgs
    }
    const getPlace = (placeId) => {
      
        const place = placesData.find(place => place._id ===placeId);
        if(!place) {
            return false
        }
        return place
    }
    const getRegion = (listing) => {
      const region = regionsData.find(region => region.id==listing.regionId);
      if(!region) {
        return false
      }
      return region
  }
  const getSuggestions = (id) => {
    return false

    const suggestions = suggestionsData.filter(suggestion => suggestion.listingId === id);
    if(!suggestions|| suggestions.length === 0) {
        return false
    }
}
const handleSuggestedEdits = () => {
  if(suggestionsSort === 'edit') {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('suggestions','');
    setSuggestionsSort(false)
    navigate(`${location.pathname}`, { replace: true });
    
  } else {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('suggestions','edit');
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
    setSuggestionsSort('edit')
  }
  setShowSuggested(false);
  handlePageChange(0)

}

/*

 {pageNumber === 0 && showSuggested &&suggestionsData && suggestionsData.filter(suggestion=>suggestion.type === 'add').map((suggestion,index) => (
        <div key={index} className='bg-yellow-400'>
                       <DisplayListing place={{_id: suggestion.listing.place, name: suggestion.listing.placeName, urlName:suggestion.listing.placeUrlName}}  suggestion={suggestion} organizers={getOrganizers(suggestion.listing.organizers)} listing={suggestion.listing} region={getRegion(listing.regionId)}  suggestionId={suggestion._id} />
        </div>
      ))}
*/

//listing page vs list item
  return (
    <div>
{!hidefilters && (
  <div className="bg-white border rounded border-black shadow-sm p-4">
  <form onSubmit={handleSubmit(fetchListings)} className="filter-form">
    <div className="flex flex-wrap sm:flex-row justify-start items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">

      {/* Type Filter */}
      <div className="z-20 w-full sm:w-80 mb-4 sm:mb-0">
        <label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Type:</label>
        <Controller
          control={control}
          name="types"
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              options={typeOptions}
              value={value ? value : []}
              onChange={onChange}
              labelledBy="Select"
              disableSearch
              hasSelectAll={true}
            />
          )}
        />
      </div>

      {/* Region Filter */}
      {!places && !organizers && (
        <div className="w-full sm:w-40 mb-4 sm:mb-0">
          <label htmlFor="regionSelect" className="block mb-2 text-sm font-medium text-gray-900">Region:</label>
          <select {...register("region")} className="block w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow focus:outline-none focus:shadow-outline">
            <option key="" value=""></option>
            {regionsData && regionsData.map(region => (
              <option key={region.id} value={region.id}>{region.name}</option>
            ))}
          </select>
        </div>
      )}

      {/* Status Filter */}
      <div className="w-full sm:w-32">
        <label htmlFor="statusSelect" className="block mb-2 text-sm font-medium text-gray-900">Listing Status:</label>
        <select {...register("status")} className="block w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow focus:outline-none focus:shadow-outline">
          <option key="" value=""></option>
          {['Live', 'Editing', 'Hiding'].map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>
      </div>

    </div>
  </form>
</div>

)}







  <div className="mt-4 flex justify-between">

        {pages.previousPage ? (
          <button
            className="bg-green-200 hover:bg-green-300 text-gray-800 font-bold py-2 px-4 rounded-l focus:outline-none focus:shadow-outline"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous Page
          </button>
        ) : (<div></div>)}

        {pages.nextPage && (
          <button
            className="bg-green-200 hover:bg-green-300 text-gray-800 font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next Page
          </button>
        )}

  </div>
    
<div className='mt-4'></div>
     
      {listingsData&& listingsData.map((listing,index) => (
        <div key={index}>{suggestionsSort === 'edit'?getSuggestions(listing.id) &&
          <div >
          <DisplayListing isAuthenticated={isAuthenticated} listing={listing} region={getRegion(listing)} suggestions={listing.edits} />
</div> :         <div key={index}>
          <DisplayListing  isAuthenticated={isAuthenticated} listing={listing} region={getRegion(listing)} suggestions={listing.edits} />
</div> 
        }</div>
        
      ))} 


<div className="mt-4 flex justify-between">

{pages.previousPage ? (
  <button
    className="bg-green-200 hover:bg-green-300 text-gray-800 font-bold py-2 px-4 rounded-l focus:outline-none focus:shadow-outline"
    onClick={() => handlePageChange(currentPage - 1)}
  >
    Previous Page
  </button>
) : (<div></div>)}

{pages.nextPage && (
  <button
    className="bg-green-200 hover:bg-green-300 text-gray-800 font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline"
    onClick={() => handlePageChange(currentPage + 1)}
  >
    Next Page
  </button>
)}

</div>
    {
        !isAuthenticated && <ListingsIntro count={totalCount} />
    }
    </div>
  );
}

export default GetListings;
