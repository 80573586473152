import { set } from "date-fns";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

function JSONParse({setValue,setAdditonalData,onChange }) {
  const { register } = useForm();
  const [messages, setMessages] = useState([]);
  const validTypes = ['Event','ChildrensEvent', 'ComedyEvent', 'DanceEvent', 'EducationEvent', 'ExhibitionEvent', 'Festival', 'LiteraryEvent', 'MusicEvent', 'PublicationEvent', 'SaleEvent', 'ScreeningEvent', 'SocialEvent', 'TheaterEvent', 'VisualArtsEvent'];

  const validateData = (data) => {
    const messages = [];
    const validData = {};

    const isValidUrl = (url) => {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    };

    const isValidDate = (dateStr) => {
        // Check if the date string can be converted to a Date object
        const date = new Date(dateStr);
        return !isNaN(date.getTime());
      };
      
      const validateDate = (startDateStr, endDateStr) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
      
        if (!isValidDate(startDateStr) || !isValidDate(endDateStr)) {
          return false;
        }
      
        // Normalize both dates to UTC to ensure fair comparison
        const now = new Date();
        const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
      
        if (endDate < today) {
          return false;
        }
        if (endDate < startDate) {
          return false;
        }
        return true;
      };
      

    if (data.name) {
        validData.name = data.name;
        messages.push("Name field is successfully filled.");
      }
      if (data.note) {
        validData.note = data.note;
        messages.push("Note field is successfully filled.");
      }
      if (data.alternateName) {
        validData.alternateName = data.alternateName;
        messages.push("Alternate Name field is successfully filled.");
      }
    if (data.description) {
      validData.description = data.description;
      messages.push("description field is successfully filled.");
    }

    if (data.url && isValidUrl(data.url)) {
      validData.url = data.url;
      messages.push("URL field is successfully filled.");
    }

    if (data.image && isValidUrl(data.image)) {
      validData.image = data.image;
      messages.push("Image field is successfully filled.");
    }

    if (data.alt) {
      validData.alt = data.alt;
      messages.push("Alt field is successfully filled.");
    }

    if (data.status) {
      validData.status = data.status;
      messages.push("Status field is successfully filled.");
    }

    if (data.sameAs && Array.isArray(data.sameAs) && data.sameAs.every(isValidUrl)) {
      validData.sameAs = data.sameAs.map((url) => ({ value: url }));
      messages.push("SameAs field is successfully filled.");
    }

    if (data.type && typeof data.type === "string" && validTypes.includes(data.type)) {
      validData.type = data.type;
      messages.push("Type field is successfully filled.");
    }


      if (data.dates && Array.isArray(data.dates)) {
        const validDates = data.dates
          .filter(({ startDate, endDate }) => validateDate(startDate, endDate))
          .map(({ startDate, endDate }) => ({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }));
          console.log(data.dates)
        if (validDates.length) {
          validData.parsedDates = validDates;
          messages.push("Dates field is successfully filled.");
        }
      }

    if (data.room && typeof data.room === "string") {
      validData.room = data.room;
      messages.push("Room field is successfully filled.");
    }

    if (data.keywords && typeof data.keywords === "string") {
      validData.keywords = data.keywords;
      messages.push("Keywords field is successfully filled.");
    }

    if (data.inLanguage && typeof data.inLanguage === "string") {
      validData.inLanguage = data.inLanguage;
      messages.push("InLanguage field is successfully filled.");
    }


    if (data.subtitleLanguage && typeof data.subtitleLanguage === "string") {
      validData.subtitleLanguage = data.subtitleLanguage;
      messages.push("subtitleLanguage field is successfully filled.");
    }

    if (typeof data.isAccessibleForFree === "boolean") {
      validData.isAccessibleForFree = data.isAccessibleForFree;
      messages.push("IsAccessibleForFree field is successfully filled.");
    }

    if (data.sponsor) {
      validData.sponsor = data.sponsor;
      messages.push("Sponsor field is successfully filled.");
    }

    if (data.worksPresented && Array.isArray(data.worksPresented)) {
        const validWorks = data.worksPresented.filter((work) => 
          work.name || work.duration || work.director || work.year || work.videoFormat ||
          work.description || work.inLanguage || work.countryOfOrigin ||
          work.productionCompany || work.TMDB || work.overview || work.about || 
          work.abstract || work.genre
        );
      
        if (validWorks.length) {
          validData.worksPresented = validWorks;
          setAdditonalData({ worksPresented: validWorks });
          messages.push("WorksPresented field is successfully filled.");
        }
      }
      
    return { validData, messages };
  };

  const handleChange = (event) => {
    try {
      const parsedData = JSON.parse(event.target.value);
      const { validData, messages } = validateData(parsedData);

      // Set valid data to the form
      Object.keys(validData).forEach((key) => {
        setValue(key, validData[key]);
      });

      setMessages(messages);
      if (onChange) onChange(validData);
    } catch (error) {
      setMessages(["Invalid JSON format."]);
    }
  };

  return (<div className="mb-6">
    <div className="text-lg font-semibold">JSON Input</div>
    <div>
      We are experimenting with a ChatGPT bot that simplifies the process of gathering and structuring event data. This bot can automatically extract and organize key information from an event page into the required JSON format, saving you both time and effort.
    </div>
    <div className="mt-2">
      See formatting details in the{" "}
      <Link to="/api" className="underline text-blue-500" target="_blank">
        API docs
      </Link>
      , or use our{" "}
      <a
        href="https://chatgpt.com/g/g-egwkgns4k-zeitgeists-org-event-json-formatter"
        className="underline text-blue-500"
        target="_blank"
      >
        JSON Formatter GPT bot
      </a>
      .
    </div>
    <textarea
      className="mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      {...register("json")}
      rows="10"
      onChange={handleChange}
    />
    <div className="mt-4">
      {messages.map((message, index) => (
        <div key={index} className="text-green-500">
          {message}
        </div>
      ))}
    </div>
  </div>
  

  );
}

export default JSONParse;
